@media (max-width: 900px) {
  .header {
    display: none;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff2b5e;
    padding: 10px;
    padding-left: 20px;
    margin: 15px 0px;
    width: 100%;
  }
}

.sidebar {
  background-color: #2a389a;
  height: 100vh;
  overflow-y: auto;
  transition: all 0.3s;
  position: relative;
  color: white;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar svg {
  color: #fff;
  margin-right: 5px;
}
.sidebar h1 {
  padding: 20px;
}
.sidebar li {
  padding: 10px;
  color: #fff;
  padding-left: 20px;
  margin: 15px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s;
}
.sidebar li:hover {
  background-color: #212c7ab4;
}
.header {
  position: sticky;
  background-color: #2a389a;
  top: 0px;
  left: 0px;
}
.hr {
  width: 100%;
  height: 1px;
  background-color: #3748ba;
}
.active {
  background-color: #212c7ab4;
  transition: all 0.3s;
}
.closed li {
  padding: 10px;
}
.icon {
  position: absolute;
  top: 15px;
  right: 10px;
}

.submenu {
  width: 100%;
}
.submenu p {
  padding: 10px 60px;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.3s;
}
.submenu p:hover {
  background-color: #212c7ab4;
}

.button {
  display: none;
  padding: 10px;
  padding-left: 20px;
  margin: 15px 0px;
  width: 100%;
}

.MenuItem {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s;
}

.MenuIcons {
  color: #fff;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .button {
    display: block;
  }

  .MenuItem {
    display: none !important;
  }
}
