.navbar {
  width: 100%;
  height: 80px;
  padding: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
}
.logo {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.logo img {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}
.links {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}
.button {
  color: var(--main-color);
  background-color: #ff2b5e;
  border-radius: 5px;
  transition: all 0.3s;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #fff;
}
.button:hover {
  box-shadow: 0px 0px 5px #000;
  background-color: #c22146;
}
