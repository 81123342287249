.container {
  padding: 0px 10px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.header h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
}
.filter {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.filter h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(40, 52, 135, 0.73);
}
.tableContainer {
  width: 100%;
  height: calc(100vh - 345px);
  overflow-y: auto;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.menu {
  position: fixed;
  display: flex;
  padding: 5px;
  margin-top: -15px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  background-color: #ebebebd7;
  backdrop-filter: blur(15px);
  z-index: 2;
  box-shadow: 0px 0px 5px rgb(172, 172, 172);
}
.menu p {
  color: #283487 !important;
  font-weight: 500;
  transition: all 0.3s;
}
.menu a {
  color: #283487 !important;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  padding: 0px 5px;
  border-radius: 10px;
  border: 1px solid #283487;
}
.menu a:hover {
  background-color: #283487;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.menu a:hover p {
  color: #fff !important;
}
.active {
  background-color: #283487;
  color: #fff;
  border: 1px solid #fff !important;
}
.active svg {
  color: #fff !important;
}
.active p {
  color: #fff !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 900px) {
  .option {
    display: none;
  }
}
