.wallet {
  border-radius: 15px;
  padding: 50px 20px;
  background: linear-gradient(90deg, #ffe5b5 0%, #fff6d4 100%);

  width: 100%;
  position: relative;
}
.wallet h1 {
  font-weight: 700;
  margin-bottom: 40px;
  font-size: 25px;
  color: #283487;
}
.wallet h2 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  color: rgb(85, 85, 85);
  color: #283487;
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
