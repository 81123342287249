.navbar {
  width: 100%;
  height: 80px;
  padding: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
}
.logo {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.logo img {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}
.links {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}
.select {
 margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select label {
  width: 100%;
  margin-right: 10px;
  color: white;
}
.profile {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}
.profile img {
  padding: 2px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.button {
  color: var(--main-color);
  background-color: #ff2b5e;
  border-radius: 5px;
  transition: all 0.3s;
  border: none;
  padding: 10px 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #fff;
}
.button:hover {
  background-color: #c22146;
}
