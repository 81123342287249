.container {
  height: 100%;
  width: 100%;
}
.container h1 {
  padding: 0px 10px 20px 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
}
.stats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
