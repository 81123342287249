.navbar {
  width: 100%;
  height: 80px;
  padding: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
}
.logo {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.logo img {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}
.links {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}
.label {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}
.year {
 
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
  color: black;
  font-weight: 900;
}
.select {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile {
  position: relative;
  background-color: #fff;
  color: #283487;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.name {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.image {
    width: 30px;
}
.button {
  color: var(--main-color);
  background-color: #ff2b5e;
  border-radius: 5px;
  transition: all 0.3s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
}
.button:hover {
  background-color: #c22146;
}
.dropdown {
  position: absolute;
  padding: 10px;
  width: 200px;
  top: 68px;
  right: -10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
}
.conditionalImage {
  display: none;
}

@media (max-width: 900px) {
  .logo img {
    width: 100px;
  }
  .label {
    display: none;
  }
  .year {
    background-color: white;
    color: black;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 900;
  }
  .profile {
   
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: default;
  }
  .name {
    display: none;
  }
  .profileSpan {
    display: none;
    color: #fff;
  }
  .logout {
    display: none;
  }
  .dropdown {
    display: none;
  }
  .image {
    display: none;
  }
  .conditionalImage {
    display: block;
    width: 25px;

   
    cursor: pointer;
  }
}
