.container {
  padding: 60px 10px 0px 10px;
}
.container table {
  width: 100%;
  border-collapse: collapse;
}
.container table thead {
  background-color: #f5f3f3;
  color: #000;
  position: sticky;
  top: 0px;
  z-index: 1;
}
.container thead tr {
  border: 1px solid #ccc !important;
}
.container table p {
  color: #000000 !important;
}
.container table th,
.container table td {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100px;
  position: relative;
}
.container table tbody tr {
  position: relative;
}
.tableContainer {
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: auto;
}
.tableContainer::-webkit-scrollbar {
  display: none;
}
.container p {
  color: #000;
  text-align: center;
}
.row {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #effaff;
  position: relative;
}
.row2 {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #e3f8b2;
  position: relative;
}
.col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgb(231, 231, 231);
}
.col:nth-of-type(1) {
  width: 30%;
}
.col:nth-of-type(2) {
  width: 70%;
}
.add {
  position: absolute;
  bottom: -20px;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;
}
.container table tr:hover .add {
  opacity: 1;
}
.edit {
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;
}
.delete {
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;
}
.reset {
  position: absolute;
  top: 0px;
  right: 5px;
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;
}
.container table thead:hover .reset {
  opacity: 1;
}
.row:hover .edit,
.row:hover .delete {
  opacity: 1;
}
